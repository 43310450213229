<template>
  <v-container min-height="100" class="px-3 regsiter-page-main">
    <v-stepper v-model="curr" color="green" class="py-6 px-5">
      <h6 class="blue--text stepper-head-text">新規会員登録</h6>
      <v-stepper-content
        class="pb-1 pt-3 px-0"
        v-for="(step, n) in steps"
        :step="n + 1"
        :key="n"
      >
        <validation-observer :ref="'observer'">
          <v-form
            :ref="'stepForm'"
            v-model="step.valid"
            lazy-validation
            autocomplete="off"
          >
            <!-- Step 1 -->
            <v-row class="ma-0" v-for="field in step.fields" :key="field.name">
              <template v-if="field.type == 'password'">
                <v-col
                  cols="12"
                  :md="field.additional_field ? '6' : '12'"
                  class="pt-0 pb-4 px-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.rules"
                    :name="field.name"
                  >
                    <v-label class="pt-1"
                      >{{ field.label }}
                      <span class="required-text">必須</span>
                    </v-label>
                    <v-text-field
                      :placeholder="field.placeholder"
                      solo
                      background-color="#EAEDF0"
                      v-model="field.value"
                      :error-messages="field.name == 'password' ? '' : errors"
                      min="8"
                      :type="field.type"
                      autocomplete="chrome-off"
                      hide-details
                    >
                    </v-text-field>
                    <span class="post-label" v-if="field.post_label">{{
                      field.post_label
                    }}</span>
                    <div class="v-messages theme--light error--text">
                      {{ errors ? errors[0] : "" }}
                    </div>
                  </validation-provider>
                </v-col>
              </template>
              <template v-if="field.type == 'text'">
                <v-col
                  cols="12"
                  :md="field.additional_field ? '6' : '12'"
                  class="pt-1 pb-4 px-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.rules"
                    :name="field.name"
                  >
                    <v-label
                      >{{ field.label }}
                      <span v-if="field.label.length" class="required-text"
                        >必須</span
                      >
                    </v-label>
                    <v-text-field
                      :id="field.name"
                      :placeholder="field.placeholder"
                      solo
                      background-color="#EAEDF0"
                      v-model="field.value"
                      :error-messages="errors"
                      @blur="
                        field.name == 'email'
                          ? checkExistingEmail(field.value)
                          : ''
                      "
                      autocomplete="chrome-off"
                      :hide-details="errors.length <= 0"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </template>
            </v-row>
          </v-form>
        </validation-observer>
        <v-btn
          block
          v-if="n + 1 <= lastStep"
          color="primary"
          class="stepper-btns mt-2"
          @click="register(n, 'true')"
          :loading="loading"
          :disabled="!step.valid"
        >
          次へ進む
        </v-btn>
        <v-btn
          v-else
          class="stepper-btns mt-2"
          block
          color="primary"
          @click="register(n, 'true')"
          :loading="loading"
          :disabled="loading || !step.valid"
        >
          登録完了
        </v-btn>
        <v-btn
          block
          class="mt-3"
          color="stepper-btns grey white--text"
          v-if="curr != 1"
          @click="curr = n"
        >
          戻る
        </v-btn>
      </v-stepper-content>
    </v-stepper>
    <div class="mt-4 text-center">
      <router-link
        :to="{ name: 'login' }"
        class="primary--text text-decoration-none"
      >
        既にアカウントをお持ちの方
      </router-link>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "RegisterPage",
  data() {
    return {
      loading: false,
      errors: "",
      formStatus: false,
      curr: 1,
      lastStep: 1,
      steps: [
        {
          name: "Step 1",
          valid: true,
          fields: [
            {
              label: "登録者名",
              name: "surname",
              type: "text",
              placeholder: "姓",
              value: "",
              rules: "required:姓"
            },
            {
              label: "",
              name: "name",
              type: "text",
              placeholder: "名",
              value: "",
              rules: "required:名"
            },
            {
              label: "フリガナ",
              name: "furigana_surname",
              type: "text",
              placeholder: "セイ",
              value: "",
              rules: "required:セイ"
            },
            {
              label: "",
              name: "furigana_name",
              type: "text",
              placeholder: "メイ",
              value: "",
              rules: "required:メイ"
            },
            {
              label: "メールアドレス(ID)",
              name: "email",
              type: "text",
              placeholder: "mail@crosspoint.com",
              value: "",
              rules: "required:メールアドレス|email"
            },
            {
              label: "パスワード",
              name: "password",
              type: "password",
              placeholder: "8文字以上の英数字を設定してください",
              value: "",
              rules:
                "required:パスワード|max:50|verify_password|only_english_lang_allowed",
              post_label: "※ 英大文字、英小文字、数字をすべて使用してください"
            },
            {
              label: "パスワード(確認用)",
              name: "password_confirmation",
              type: "password",
              placeholder: "もう一度入力してください",
              value: "",
              rules:
                "required:パスワード(確認用)|max:50|password_confirmed:password"
            }
          ]
        },
        {
          name: "Complete",
          valid: true,
          fields: [
            {
              label: "会社名/店舗名",
              name: "shop_name",
              type: "text",
              placeholder: "クロスポイント株式会社",
              value: "",
              rules: "required:会社名/店舗名"
            },
            {
              label: "役職",
              name: "position",
              type: "text",
              placeholder: "エリアマネージャー",
              value: "",
              rules: "required:役職"
            },
            {
              label: "電話番号",
              name: "telephone_number",
              type: "text",
              placeholder: "000-0000-0000",
              value: "",
              rules: "required:電話番号|enter_half_width_numbers_hyphens"
            }
          ]
        }
      ],
      valid: false,
      stepForm: [],
      observer: []
    };
  },
  created() {
    history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function() {
      history.pushState(null, null, document.URL);
    });
  },
  methods: {
    stepComplete(step) {
      return this.curr > step;
    },
    stepStatus(step) {
      return this.curr > parseInt(step) ? "green" : "blue";
    },
    moveToNextStep(n) {
      this.steps[n].valid = false;
      this.$refs.observer[n].validate().then(success => {
        if (!success) {
          return;
        }
      });
      this.steps[n].valid = true;
      // continue to next
      this.curr = n + 2;
    },
    register(currentStep = "false", clicked = "false") {
      this.$refs.observer[currentStep].validate().then(success => {
        if (!success) {
          return;
        }

        if (currentStep != this.lastStep) {
          this.moveToNextStep(currentStep);
          return;
        }

        this.loading = true;
        let data = new FormData();
        this.steps.forEach(step => {
          step.fields.forEach(field => {
            if (field.value) {
              if ({}.hasOwnProperty.call(field, "additional_field")) {
                data.append(
                  field.additional_field.name,
                  field.additional_field.value
                );
              }
              data.append(field.name, field.value);
            }
          });
        });

        this.$store
          .dispatch("USER_REGISTER", data)
          .then(() => {
            this.$router.push({ name: "add_store" });
            this.$store.dispatch("MASTER_GET_ALL");
            this.$store.dispatch("NOTIFICATION_UNREAD_COUNT_GET");
          })
          .catch(error => {
            this.curr = 1;
            this.steps.forEach((value, key) => {
              if (error?.data?.errors)
                this.$refs.observer[key].setErrors(error?.data?.errors);
            });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    checkExistingEmail(value) {
      this.$store
        .dispatch("USER_CHECK_EXISTING_EMAIL", {
          email: value
        })
        .catch(error => {
          this.steps.forEach((value, key) => {
            if (error?.data?.errors)
              this.$refs.observer[key].setErrors(error?.data?.errors);
          });
        });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
